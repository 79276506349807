import { Injectable } from '@angular/core';
import { UtilService } from '../svc/utilService'
import { ErrorService } from '../svc/errorService';
import { Constants } from '../api/Constants';
import { Observable, Subject } from 'rxjs';

import * as CRS from '../api/CRS';
import * as SVC from '../api/SVC';

@Injectable({
    providedIn: 'root'
})
export class UserCacheService {

    constructor(
        private utilService: UtilService,
        private errorService: ErrorService,
        private userService: SVC.UserService)
    { 
        this._onUserLoad=new Subject<CRS.RSGetUserInformation>();
    }

    private userInfo: CRS.RSGetUserInformation=null;
    
    public errorText:string=null;
    public inError : boolean = false;

    private _initiated:boolean=false;
    public ensureUser(force:boolean=false) {
        if (this._initiated && !force)
            return;
        this._initiated=true;
        this.userService.getUserInformation().then((data)=> {
            this.userInfo = <CRS.RSGetUserInformation>data.data;
            this.utilService.setUserFeatures(this.userInfo.privileges);
            if (!this.utilService.serverId || this.utilService.serverId == "null")
                this.utilService.serverId = this.userInfo.contextId;
            if (!data.data.servers.find(x=>x.id == this.utilService.serverId)) // if current server no longer valid
                this.utilService.serverId = data.data.servers[0].id;
            this.inError=false;
            this._onUserLoad.next(this.userInfo);
        }).catch((err)=>{
            this.inError=true;
        });
    }

    private _onUserLoad : Subject<CRS.RSGetUserInformation>;
    public onUserLoaded() {
        return this._onUserLoad.asObservable();
    }

    public get isReady() : boolean {
        return !this.utilService.isEmptyAny( this.userInfo);
    }

    public GetUserInfo() : CRS.RSGetUserInformation {
        if (this.userInfo)
            return this.userInfo;
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    public hasToken() : boolean {
        return this.utilService.hasToken();
    }
 
    public createColumnSetProfile(name:string, cols: Array<string>) : Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            this.userService.createUserProfileColumnSet(name, cols).then((response)=> {
                var uinf =this.GetUserInfo();
                uinf.columnProfiles = response.data.profiles;
                uinf.lastSelectedProfileId=response.data.createdProfileId;
                localStorage.setItem("userInfo", JSON.stringify(uinf));
                resolve(true);
            }), (error) => {
                this.errorService.show(
                    'Problem retrieving data',
                    `There's been a problem creating user profile column set`,
                    `Please try again, log out/in or contact support`,
                    error);
            }
        });
    }

    public registerSelectedUserProfile(profileId:string) :Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {

            this.userService.setPreferredProfileColumnSet(profileId).then((ret)=> {
                this.GetUserInfo().columnProfiles.forEach(x=> {
                    x.isCurrentProfile = (x.profileId == profileId);
                });
                this.GetUserInfo().lastSelectedProfileId = profileId;
                resolve(true);
            }).catch((ex)=> {
                reject();
            });

        });
    }

    public updateUserProfileColumns(profileId:string, cols: Array<string>){
        this.userService.setProfileColumnSetColumns(profileId,cols).then((result)=>{
            var lst = this.GetUserInfo().columnProfiles.filter(x=>x.profileId == profileId);
            if (lst.length>0)
                lst[0].columnNames = cols;
        });
    }

    public renameUserProfile(profileId:string, name:string) {
        this.userService.setProfileColumnSetName(profileId, name).then((result)=> {
            var lst = this.GetUserInfo().columnProfiles.filter(x=>x.profileId == profileId);
            if (lst.length>0)
                lst[0].profileName = name;
        });
    }

    public deleteUserProfile(profileId:string){
        this.userService.deleteProfileColumnSet(profileId).then((result)=> {
            var profiles = this.GetUserInfo().columnProfiles;
            var lst = profiles.filter(x=>x.profileId == profileId);
            if (lst.length>0)
                var idx = profiles.indexOf(lst[0]);
                if (idx!=-1)
                    this.GetUserInfo().columnProfiles.splice(idx,1);
        });
    }

    public syncCurrentServer(serverId:string) {
        this.userInfo.contextId = serverId;
    }

    public getUserGroup(groupId:string) : Promise<Array<CRS.RSUser>>
    {
        return new Promise<Array<CRS.RSUser>>((resolve,reject)=> {
            this.userService.getUserGroup(groupId??"*").then((response)=>{
                resolve(response.data.users);
            }, (ex)=> {
                reject(ex);
            });
        });
    }

    public get hasServerOptions(): boolean {
        return this.GetUserInfo().servers.length > 1;
    }

    private recordError(err:any) {
        var errorType = (err.data) ? err.data.exceptionType : null;
        if (errorType == "System.Security.Authentication.AuthenticationException") {
            this.errorText = "Please check your user name and password.";
        }
        else {
            this.errorText = "Unknown Error";
        }

    }

}
    

